import React, { useState, useEffect } from 'react';

import { StyledContentContainer, StyledTitle, StyledFeaturedImage, StyledQuote, StyledQuoteText, StyledQuoteImage, StyledButton } from './styles';

import { StyledModuleContainer } from '@styles/global-components/_containers';
import { twitterShare } from '@helpers/_twitter-share';

import QuoteImage from '@images/quote-image.png';
import { stripParagraphTags } from '@helpers/_strip-paragraph-tags';

export default function SingleQuote({
    module, 
}) {

     if(module) return (
        <StyledModuleContainer
            $sectionOptions={module.sectionOptions}
            moduleSpacing={'md'}
            id={module.sectionOptions && module.sectionOptions.sectionId}
            data-name="quote"
        >
            <StyledContentContainer> 
                {module.title && 
                    <StyledTitle
                        titleFontOptions={{ mobile: 'sm', desktop: 'sm' }}
                    >
                        {module.title}
                    </StyledTitle>
                }
                <div className="image-col">
                    {module.featuredImage && 
                        <StyledFeaturedImage
                            imageFile={module.featuredImage.imageFile}
                            alt={module.featuredImage.altText}
                        />
                    }
                </div>
                <div className="content-col">
                    <StyledQuote>
                        {module.text && 
                            <StyledQuoteText
                                bodyFontOptions={{ mobile: 'sm', desktop: 'sm' }}
                            >
                                {module.text}
                            </StyledQuoteText>
                        }     

                        {/* <StyledQuoteImage 
                            src={QuoteImage}
                            alt={'Quote background graphic'}
                        /> */}
                    </StyledQuote>

                    {module.featuredImage && 
                        <StyledFeaturedImage
                            className="mobile-featured-image"
                            imageFile={module.featuredImage.imageFile}
                            alt={module.featuredImage.altText}
                        />
                    }

                    {module.text && 
                        <StyledButton 
                            href={twitterShare({
                                text: stripParagraphTags(module.text)
                            })} 
                            buttonOptions={module.buttonOptions}
                            fontSize={'md'} 
                            radius={'md'}
                        >
                            >>>> Tweet this!
                        </StyledButton>
                    }
                </div> 
                
            </StyledContentContainer>
        </StyledModuleContainer>
    )
    return null;
}