import styled, { css } from 'styled-components';
import { theme } from '@theme';

import { StyledContainer } from '@styles/global-components/_containers';

import WrButton from '@commons/wr-button';
import WrImage from '@commons/wr-image';
import ModuleTitle from '@commons/module-title';
import WysiwygContent from '@commons/wysiwyg-content';

export const StyledContentContainer = styled(StyledContainer)`

    ${theme.mediaQuerys.smUp} {
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
    }

    .image-col {
        display: none;

        ${theme.mediaQuerys.smUp} {
            display: block;
            width: 50%;
            margin-bottom: -33px;
        }

        ${theme.mediaQuerys.mdUp} {
            margin-bottom: -43px;
        }

        ${theme.mediaQuerys.lgUp} {
            margin-bottom: -53px;
            width: 55%;
        }
    }

    .content-col {
        flex: 1;
    }

    .mobile-featured-image {

        ${theme.mediaQuerys.smUp} {
            display: none;
        }
    }
`

export const StyledTitle = styled(ModuleTitle)`
    width: 100%;    
    text-align: center;
    margin-bottom: 25px;

    ${theme.mediaQuerys.mdUp} {
        margin-bottom: 15px;

    }

    &::after {
        content: '';
        display: none;
        width: 80%;
        max-width: 700px;
        margin: 20px auto 0 auto;
        border-bottom: 1px solid #C4C4C4;

        ${theme.mediaQuerys.smUp} {
            display: block;
        }

        ${theme.mediaQuerys.mdUp} {
            margin: 15px auto 0 auto;
        }
    }
`

export const StyledQuote = styled.div`
    position: relative;
    margin-bottom: 30px;
`

export const StyledQuoteText = styled(WysiwygContent)`
    text-align: center;
    font-weight: bold;
        
    ${theme.mediaQuerys.smUp} {
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 20px;
        padding: 30px 20px;
        width: 100%;
        /* position: absolute;
        top: 50%;
        left: 40px;
        transform: translateY(-50%); */
        /* padding-top: 10px; */
        text-align: left;
        font-weight: 400;
        color: ${theme.colors.black};
        max-width: 350px;
    }


    ${theme.mediaQuerys.lgUp} {
        padding: 30px 25px;
        max-width: 400px;
        /* max-width: 300px; */
        /* padding-top: 20px; */
        /* left: 50px; */
    }
`

export const StyledQuoteImage = styled(WrImage)`
    display: none;

    ${theme.mediaQuerys.smUp} {
        display: block;
        width: 100%;
        max-width: 0px;
    }

    ${theme.mediaQuerys.lgUp} {
        max-width: 480px;
    }
`

export const StyledFeaturedImage = styled(WrImage)`
    width: 90%;
    margin: 0 auto;
    max-width: 350px;

    ${theme.mediaQuerys.smUp} {
        max-width: 400px;
    }

    ${theme.mediaQuerys.lgUp} {
        max-width: 440px;
    }
`

export const StyledButton = styled(WrButton)` 
    margin: 0 auto;

    ${theme.mediaQuerys.smUp} {
        max-width: 282px;
        margin-left: 0;
        /* margin-left: 15px; */
    }

    ${theme.mediaQuerys.lgUp} {
        max-width: 325px;
        /* margin-left: 20px; */
    }
`