


export const twitterShare = ({
    text,
}) => {
    let isBrowser = false;
    if (typeof window !== 'undefined') isBrowser = true;

    if(isBrowser) return `http://twitter.com/share?text=${encodeURI(text)}&url=${window.location.href}`;
    return null;
}
