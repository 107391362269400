import React from "react";

import SingleQuote from "./single-quote";

const Start = ({ module = { display: '' } }) => {
    const { quote } = module;
    const { display } = quote;

    switch(display) {
        case "single-quote":
            return <SingleQuote module={quote} />;
        default:
            return null;
    }
};

export default Start;